<!--
主界面
最后编辑人：ls
最后编辑时间：2023-1-10
最后编辑细节：
-->
<template>
  <el-container style="width: 100%; height: 100%;">
    <el-header height="60px" class="main-header">
      <el-row type="flex" justify="space-between" style="height: 100%">
        <el-col :span="10" class="header-title" >闪算云企业数字化盈利管理平台</el-col>
        <el-col :span="12" class="user-block">
          <span style="padding-right: 10px; font-size: 20px;">{{ userinfo.organname }}</span>
          <span style="padding-right: 10px;">{{ userinfo.departmentname }}</span>
          <span style="padding-right: 30px;">{{ userinfo.urrole }}</span>
          <el-dropdown trigger='click'>
            <div class="avatar_div">
              <i class="iconfont icon-avatar icon-yonghutouxiang"></i>
              <span style="margin: 0 10px">{{ userinfo.urname }}</span>
              <el-icon><arrow-down /></el-icon>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <div class="menu-item">
                    <i class="iconfont icon-small icon-gerenxinxi"></i>
                    <span>个人消息</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item @click="showChangePwd">
                  <div class="menu-item">
                    <i class="iconfont icon-small icon-xiugaimima"></i>
                    <span>修改密码</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item divided @click="exit">
                  <div class="menu-item">
                    <el-icon :size="18" color="#f56c6c"><SwitchButton /></el-icon>
                    <span style="color: #f56c6c">退出系统</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>

    <el-container style="height: calc(100% - 60px);">
      <el-aside :width="menuWidth" class="main-nav back">
        <div style="text-align: right">
          <el-button size="default" type="text" @click="collapseMenu" class="icon-button">
            <div v-if="menuCollapse"><el-icon :size="26"><Expand /></el-icon></div>
            <div v-else><el-icon :size="26"><Fold /></el-icon></div>
          </el-button>
        </div>
        <el-scrollbar style="height: calc(100% - 40px);">
          <el-menu
              :default-active="$route.path"
              router
              :collapse="menuCollapse"
              :unique-opened="true"
              background-color="#455a64"
              text-color="#FFFFFF"
              active-text-color="#ffd04b"
              style="border-right: none;"
          >
            <el-menu-item index="/homepage" v-if="this.checkCatalog('/homepage')">
              <i class="iconfont icon-large icon-shouye"></i>
              <span class="menu-fone">首页</span>
            </el-menu-item>

            <el-sub-menu index="info" v-if="this.checkCatalog('/info')">
              <template #title>
                <i class="iconfont icon-large icon-nav_icon_jcsjgl_spe"></i>
                <span class="menu-fone">数据管理</span>
              </template>
              <el-menu-item index="/info/codemge" v-if="this.checkUrl('/info/codemge')"><el-icon><Collection /></el-icon>字典管理</el-menu-item>
              <el-menu-item index="/info/subjectmge" v-if="this.checkUrl('/info/subjectmge')"><i class="iconfont icon-kemuguanli" style="font-size: 24px;"></i>科目管理</el-menu-item>
              <el-menu-item index="/info/businessmge" v-if="this.checkUrl('/info/businessmge')"><i class="iconfont icon-yewuleixing" style="font-size: 18px;"></i>业务类型管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="voucher" v-if="this.checkCatalog('/voucher')">
              <template #title>
                <i class="iconfont icon-large icon-pingzhengguanli"></i>
                <span class="menu-fone">凭证管理</span>
              </template>
              <el-menu-item index="/voucher/voucherenter" v-if="this.checkUrl('/voucher/voucherenter')"><i class="iconfont icon-pingzhengluru" style="font-size: 16px;"></i>凭证录入</el-menu-item>
              <el-menu-item index="/voucher/voucherdetail" v-if="this.checkUrl('/voucher/voucherdetail')"><i class="iconfont icon-pingzhengmingxichaxun" style="font-size: 16px;"></i>出纳凭证明细</el-menu-item>
              <el-menu-item index="/voucher/initialdatamge" v-if="this.checkUrl('/voucher/initialdatamge')"><i class="iconfont icon-qichushuju" style="font-size: 18px;"></i>期初数据管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="report" v-if="this.checkCatalog('/report')">
              <template #title>
                <i class="iconfont icon-large icon-baobiao"></i>
                <span class="menu-fone">报表查询</span>
              </template>
              <el-menu-item index="/report/dayRecordReport" v-if="this.checkUrl('/report/dayRecordReport')"><i class="iconfont icon-a-016" style="font-size: 18px;"></i>日记账管理</el-menu-item>
              <el-menu-item index="/report/monthFinancialReport" v-if="this.checkUrl('/report/monthFinancialReport')"><i class="iconfont icon-yuezijinjiesuan" style="font-size: 20px;"></i>出纳资金月报表</el-menu-item>
              <el-menu-item index="/report/profitAndLossReport" v-if="this.checkUrl('/report/profitAndLossReport')"><i class="iconfont icon-ccgl-sunyiguanli-3" style="font-size: 18px;"></i>实时损益表</el-menu-item>
              <el-menu-item index="/report/cashflowreport" v-if="this.checkUrl('/report/cashflowreport')"><i class="iconfont icon-xianjinliubaobiao" style="font-size: 18px;"></i>现金流量报表</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="wages" v-if="this.checkCatalog('/wages')">
              <template #title>
                <i class="iconfont icon-large icon-jixiaoguanli"></i>
                <span class="menu-fone">绩效管理</span>
              </template>
              <el-menu-item index="/wages/wagesMge" v-if="this.checkUrl('/wages/wagesMge')"><i class="iconfont icon-gongziguanli" style="font-size: 18px;"></i>工资及绩效管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="asset" v-if="this.checkCatalog('/asset')">
              <template #title>
                <i class="iconfont icon-large icon-zichanguanli1"></i>
                <span class="menu-fone">资产管理</span>
              </template>
              <el-menu-item index="/asset/assetStorageMge" v-if="this.checkUrl('/asset/assetStorageMge')"><i class="iconfont icon-churukuguanli" style="font-size: 18px;"></i>出入库管理</el-menu-item>
              <el-menu-item index="/asset/assetInventoryBase" v-if="this.checkUrl('/asset/assetInventoryBase')"><i class="iconfont icon-xiaoshou_shangpinchuruku" style="font-size: 18px;"></i>商品出入库明细</el-menu-item>
              <el-menu-item index="/asset/assetStorageQuery" v-if="this.checkUrl('/asset/assetStorageQuery')"><i class="iconfont icon-kucunchaxun" style="font-size: 18px;"></i>库存查询</el-menu-item>
              <el-menu-item index="/asset/assetDepreciationMge" v-if="this.checkUrl('/asset/assetDepreciationMge')"><i class="iconfont icon-zichanzhejiubaobiao" style="font-size: 18px;"></i>资产折旧管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="liabilities" v-if="this.checkCatalog('/liabilities')">
              <template #title>
                <i class="iconfont icon-large icon-fuzhaiguanli"></i>
                <span class="menu-fone">负债管理</span>
              </template>
              <el-menu-item index="/liabilities/liabilitiesMge" v-if="this.checkUrl('/liabilities/liabilitiesMge')"><i class="iconfont icon-fuzhaiguanli" style="font-size: 18px;"></i>负债及利息管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="entry" v-if="this.checkCatalog('/entry')">
              <template #title>
                <i class="iconfont icon-large icon-zuocedaohang_shanshiweiyuanhuijilu"></i>
                <span class="menu-fone">会计管理</span>
              </template>
              <el-menu-item index="/entry/entryDetail" v-if="this.checkUrl('/entry/entryDetail')"><i class="iconfont icon--FLduizhangguanli" style="font-size: 18px;"></i>会计分录明细</el-menu-item>
              <el-menu-item index="/entry/subjectBalanceReport" v-if="this.checkUrl('/entry/subjectBalanceReport')"><i class="iconfont icon-kemuyuebiao" style="font-size: 18px;"></i>会计科目余额</el-menu-item>
              <el-menu-item index="/entry/subjectDetailReport" v-if="this.checkUrl('/entry/subjectDetailReport')"><i class="iconfont icon-a-016" style="font-size: 18px;"></i>科目明细查询表</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="entryreport" v-if="this.checkCatalog('/entryreport')">
              <template #title>
                <i class="iconfont icon-large icon-huijibaobiao"></i>
                <span class="menu-fone">会计报表</span>
              </template>
              <el-menu-item index="/entryreport/balanceReport" v-if="this.checkUrl('/entryreport/balanceReport')"><i class="iconfont icon-zichanfuzhaibiao" style="font-size: 18px;"></i>资产负债表</el-menu-item>
              <el-menu-item index="/entryreport/profitReport" v-if="this.checkUrl('/entryreport/profitReport')"><i class="iconfont icon-lirunbiao" style="font-size: 18px;"></i>利润表</el-menu-item>
              <el-menu-item index="/entryreport/entryCashFlowReport" v-if="this.checkUrl('/entryreport/entryCashFlowReport')"><i class="iconfont icon-xianjinliuliangbiao" style="font-size: 18px;"></i>现金流量表</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="organ" v-if="this.checkCatalog('/organ')">
              <template #title>
                <i class="iconfont icon-large icon-xitongquanxian"></i>
                <span class="menu-fone">组织权限</span>
              </template>
              <el-menu-item index="/organ/organmge" v-if="this.checkUrl('/organ/organmge')"><i class="iconfont icon-small icon-zuzhiguanli"></i>组织管理</el-menu-item>
              <el-menu-item index="/organ/rolemge" v-if="this.checkUrl('/organ/rolemge')"><i class="iconfont icon-small icon-jiaoseguanli1"></i>角色管理</el-menu-item>
              <el-menu-item index="/organ/usermge" v-if="this.checkUrl('/organ/usermge')"><i class="iconfont icon-small icon-jiaoseguanli2"></i>用户管理</el-menu-item>
              <el-menu-item index="/organ/permissionmge" v-if="this.checkUrl('/organ/permissionmge')"><i class="iconfont icon-small icon-quanxianguanli"></i>权限管理</el-menu-item>
              <el-menu-item index="/organ/userrolepermission" v-if="this.checkUrl('/organ/userrolepermission')"><i class="iconfont icon-small icon-quanxianguanli1"></i>用户角色授权管理</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="sys" v-if="this.checkCatalog('/sys')">
              <template #title>
                <el-icon :size="24"><Tools /></el-icon>
                <span class="menu-fone">系统管理</span>
              </template>
              <el-menu-item index="/sys/systemConfiguration" v-if="this.checkUrl('/sys/systemConfiguration')"><i class="iconfont icon-small icon-denglurizhi" style="font-size: 18px;"></i>系统配置</el-menu-item>
              <el-menu-item index="/sys/syslog" v-if="this.checkUrl('/sys/syslog')"><i class="iconfont icon-small icon-caozuorizhi"></i>系统日志</el-menu-item>
              <el-menu-item index="/sys/loginlog" v-if="this.checkUrl('/sys/loginlog')"><i class="iconfont icon-small icon-denglurizhi" style="font-size: 18px;"></i>登录日志</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <el-main id="maincontainer">
        <router-view v-slot="{ Component }">
          <component ref="child" :is="Component" />
        </router-view>
        <el-backtop target="#maincontainer"></el-backtop>
        <el-dialog title="修改密码" width="400px" v-model="pwdChangeVisi">
          <el-form :model="pwdData" :rules="pwdrules" ref="pwdForm" label-width="90px" @submit.prevent>
            <el-form-item label="原密码" prop="oldpwd">
              <el-input v-model="pwdData.oldpwd" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newpwd">
              <el-input v-model="pwdData.newpwd" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confpwd">
              <el-input v-model="pwdData.confpwd" show-password></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: center">
            <el-button type="primary" @click="doChangePwd">保 存</el-button>
          </div>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import {ElNotification} from "element-plus";

export default {
  name: "MainPage",
  data () {
    let validConfPwd = (rule, value, callback) => {
      if (this.pwdData.newpwd !== value)
        callback(new Error("新密码与确认密码不一致"))
      else
        callback()
    }
    return {
      pwdChangeVisi: false,
      menuWidth: "240px",
      menuCollapse: false,
      userinfo: {
        account: "",
        urname: "",
        urrole:"",
        orname: "",
        urls: [],
        organname:"",
        departmentname:"",
      },
      pwdData: {
        oldpwd: "",
        newpwd: "",
        confpwd: "",
      },
      pwdrules: {
        oldpwd: [{ required: true, message: '请输入原密码', trigger: 'blur'}],
        newpwd: [{ required: true, message: '请输入新密码', trigger: 'blur'}, {pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\s\S]{8,16}$/, message: "登录密码必须包含大写字母、小写字母、数字，长度8-16个字符", trigger: "blur"}],
        confpwd: [{ required: true, message: '请输入确认密码', trigger: 'blur' }, { validator: validConfPwd, trigger: 'blur' }]
      },

    }
  },
  mounted() {
    const {path} = this.$route
    if (path.toLowerCase() === "/mainpage"){
      this.$router.replace({name: "home-page"})
    }
    this.loadUserInfo()
  },
  methods: {
    checkCatalog (url) {
      let f = this.userinfo.urls.find((item) => {
        return item.indexOf(url) === 0
      })
      return typeof f !== "undefined"
    },
    checkUrl (url) {
      let f = this.userinfo.urls.find((item) => {
        return item === url
      })
      return typeof f !== "undefined"
    },
    collapseMenu () {
      if (this.menuCollapse) {
        this.menuWidth = "240px"
        this.menuCollapse = false
      }
      else {
        this.menuWidth = "60px"
        this.menuCollapse = true
      }
    },
    loadUserInfo () {
      this.$axios.get("/api/login/userinfo")
          .then((response) => {
            if (response.data.success) {
              let info = response.data.result
              sessionStorage.setItem("organ-id", info.user.USER_ID)
              sessionStorage.setItem("organ-account", info.user.account)
              sessionStorage.setItem("organ-name", info.user.name)
              sessionStorage.setItem("organ-role", info.user.role)
              sessionStorage.setItem("organ-role-name", info.user.rolename)
              sessionStorage.setItem("organ-cellphone", info.user.cellphone)
              sessionStorage.setItem("organ-organid", info.user.organId)
              sessionStorage.setItem("organ-organname", info.user.organname)
              sessionStorage.setItem("organ-departmentname", info.user.departmentname)
              sessionStorage.setItem("IsVoucher", info.user.IsVoucher)
              this.userinfo.account = sessionStorage.getItem("organ-account")
              this.userinfo.urname = sessionStorage.getItem("organ-name")
              this.userinfo.urrole = sessionStorage.getItem("organ-role-name")
              this.userinfo.organname = sessionStorage.getItem("organ-organname")
              this.userinfo.departmentname = sessionStorage.getItem("organ-departmentname")
              this.userinfo.urls = info.pages
            } else {
              this.$alert(response.data.result, "获取登录用户信息出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取登录用户信息出错")
          })
    },
    showChangePwd () {
      this.pwdData = {
        oldpwd: "",
        newpwd: "",
        confpwd: ""
      }
      this.pwdChangeVisi = true
      if (this.$refs.pwdForm)
        this.$refs.pwdForm.clearValidate()
    },
    doChangePwd () {
      this.$refs.pwdForm.validate((valid) => {
        if (valid) {
          this.$axios.post("/api/login/changepwd", this.pwdData)
              .then((response) => {
                if (response.data.success) {
                  this.$message({message: "修改成功", type: "success"})
                  this.pwdChangeVisi = false
                } else {
                  this.$alert(response.data.result, "修改密码出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "修改密码出错")
              })
        }
      })
    },
    exit () {
      ElNotification.closeAll()
      setTimeout(()=>{   //设置延迟执行
        this.$axios.get("/api/login/dologout")
        sessionStorage.removeItem("organ-account")
        sessionStorage.removeItem("organ-name")
        sessionStorage.removeItem("organ-role")
        sessionStorage.removeItem("organ-role-name")
        sessionStorage.removeItem("organ-cellphone")
        sessionStorage.removeItem("organ-organname")
        sessionStorage.removeItem("organ-departmentname")
        this.$router.replace({name: "login-page"})
        localStorage.removeItem('sessionId')
      },1000);
    },

  },
}
</script>

<style scoped>
.main-header {
  position: relative;
  padding-top: 3px;
  padding-bottom: 2px;
  background-color: #455a64;
  color: #ffffff;
  overflow: hidden;
}

.header-title {
  line-height: 50px;
  max-height: 60px;
  letter-spacing: 7px;
  text-shadow: #2c80c5 0 0 3px;
  color: #e6fffd;
  font-size: 30px;
  font-family: "PingFang SC", sans-serif;
}

.user-block {
  max-height: 60px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.avatar_div {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
.avatar_div:hover {
  cursor: pointer;
}
.icon-avatar{
  font-size: 38px;
  color: #fff;
  margin-right: 10px;
}
.main-nav {
  background-color: #455a64;
  transition: 600ms;
}

.menu-fone{
  font-size: 16px;
  margin-left: 10px;
}
.icon-button {
  width: 60px;
  color: white;
  transition: all 0.3s;
}
.icon-button:hover {
  color: white;
  transform: scaleX(1.2) scaleY(1.2) translateZ(0);
}
.menu-item{
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
