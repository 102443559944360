<template>
  <el-container class="base-container">
    <el-header class="base-height">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>会计管理</el-breadcrumb-item>
        <el-breadcrumb-item>会计科目余额表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-container class="main-container" ref="mainSector">
      <el-main style="height: 100%; border-left: 10px solid #f5f5f5; overflow:hidden;">
        <el-row style="margin-bottom: 10px">
          <el-col :span="3" class="input-search">
            <el-date-picker
                v-model="search.recordMonth"
                type="month"
                placeholder="选择年月"
                style="width: 100%"
                value-format="YYYY-MM"
                format="YYYY年MM月"
                @change="loadData()"
            />
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择公司或部门"
                style="width: 100%;"
                v-model="search.organOrDep"
                :props="organprops"
                clearable
                :show-all-levels="false"
                @change="loadData($event)"
                :options="OrganTreeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectType" placeholder="请选择科目类别" @change="loadData" clearable>
              <el-option
                  v-for="item in subjectTypeData"
                  :key="item.CODE"
                  :label="item.CODE_NAME"
                  :value="item.CODE">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-cascader
                placeholder="请选择会计科目"
                style="width: 100%;"
                v-model="search.subjectSort"
                :props="props"
                clearable
                :show-all-levels="false"
                @change="changeDetail"
                :options="treeData" >
            </el-cascader>
          </el-col>
          <el-col :span="3" class="input-search">
            <el-select v-model="search.subjectDetail" placeholder="请选择科目明细" clearable @change="loadData()" :disabled="disabled">
              <el-option
                  v-for="item in subjectDetailList"
                  :key="item.SUBJECT_DETAIL"
                  :label="item.SUBJECT_DETAIL"
                  :value="item.SUBJECT_DETAIL">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="8">
            <el-dropdown>
              <el-button type="success" >
                <i class="iconfont icon-gongneng" style="font-size: 14px"></i><span style="margin-right: 5px">会计功能 </span>  <el-icon><ArrowDown /></el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-link @click="showProvisionTaxes()" type="success" :underline="false" >
                      <i class="iconfont icon-jitishuijin" style="font-size: 16px"></i>计提税金及附加
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="doCheckout()" type="success" :underline="false" >
                      <i class="iconfont icon-yicijiezhang" style="font-size: 20px"></i>会计结账
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="showProvision()" type="success" :underline="false" >
                      <i class="iconfont icon-yijiti"></i>计提企业所得税
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="doTransfer()" type="success" :underline="false" >
                      <i class="iconfont icon-qimojiezhuan-normal" style="font-size: 18px"></i>利润结转
                    </el-link>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-link @click="showProvisionSurplus()" type="success" :underline="false" >
                      <i class="iconfont icon-sheshuixinxitongji" style="font-size: 18px"></i>计提盈余公积
                    </el-link>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button type="success" @click="doMonthTransfer" style="margin-left: 10px;">
              <span><i class="iconfont icon-qimojiezhuan-normal" style="font-size: 18px"></i>月末结转</span>
            </el-button>
            <el-button type="primary" @click="toPrint()"  style="margin-left: 10px;">
              <i class="iconfont icon-print" style="font-size: 15px"></i><span style="margin-right: 5px">打印</span>
            </el-button>
            <el-button type="warning" @click="doExport()" :loading="exportTip !== ''" style="margin-left: 10px;">
              <i class="iconfont icon-daochu" style="font-size: 15px"></i><span style="margin-right: 5px">导出</span>
            </el-button>
            <a ref="expLink" target="_blank" style="display: none;"></a>
            <span style="font-size: 12px; margin-left: 20px">{{ exportTip }}</span>
          </el-col>
          <el-col :span="1" style="text-align: right;">
            <el-tooltip effect="dark" content="刷新" placement="top">
              <el-link :underline="false" @click="toRefresh()">
                <el-icon :size="20"><Refresh /></el-icon>
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>

        <el-table
            :height="tableHeight"
            border
            :span-method="objectSpanMethod"
            :data="subjectBalanceData"
            :cell-style="cellStyleMethod">
          <el-table-column
              prop="SUBJECT_TYPE_NAME"
              label="科目类型"
              show-overflow-tooltip
              align="center"
              min-width="60px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_NAME"
              label="科目名称"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="SUBJECT_DETAIL"
              label="科目明细"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
          </el-table-column>
          <el-table-column
              prop="total_init_debtor"
              label="期初借方(元)"
              align="center"
              show-overflow-tooltip
              min-width="60px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_init_debtor) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_init_credit"
              label="期初贷方(元)"
              align="center"
              show-overflow-tooltip
              min-width="60px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_init_credit) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_debtor"
              label="借方金额(元)"
              align="center"
              show-overflow-tooltip
              min-width="80px"
          >
            <template #default="scope">
              <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_debtor) }}</el-button>
              </div>
              <div v-else>{{ formatNumber(scope.row.total_debtor) }}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="total_credit"
              label="贷方金额(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              <div v-if="scope.row.SUBJECT_TYPE !== '合计' && scope.row.SUBJECT_TYPE !== '总计' ">
                <el-button type="text" size="default" @click="goDayRecord(scope.row)">{{ formatNumber(scope.row.total_credit) }}</el-button>
              </div>
              <div v-else>{{ formatNumber(scope.row.total_credit) }}</div>
            </template>
          </el-table-column>
          <el-table-column
              prop="total_end_debtor"
              label="期末借方(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_end_debtor) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="total_end_credit"
              label="期末贷方(元)"
              show-overflow-tooltip
              align="center"
              min-width="80px"
          >
            <template #default="scope">
              {{ formatNumber(scope.row.total_end_credit) }}
            </template>
          </el-table-column>
        </el-table>

        <el-dialog
            width="500px"
            title="计提税金及附加"
            v-model="dailVisiProvisionTaxes"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-input v-model="taxes" placeholder="请录入应交增值税-销项税金额" oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')" style="margin-bottom: 5px">
            <template #append>元</template>
          </el-input>
          <el-input v-model="CjtaxRate" placeholder="请录入城市维护建设税率" style="margin-bottom: 5px">
            <template #append>%</template>
          </el-input>
          <el-input v-model="JytaxRate" placeholder="请录入教育费附加税率" style="margin-bottom: 5px">
            <template #append>%</template>
          </el-input>
          <el-input v-model="DfjytaxRate" placeholder="请录入地方教育费附加税率">
            <template #append>%</template>
          </el-input>
          <div class="div-button" style="margin-top: 20px">
            <el-button type="primary" @click="toProvisionTaxes" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="计提企业所得税"
            v-model="dailVisiProvision"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-input v-model="taxRate"  placeholder="请录入适用企业所得税税率">
            <template #append>%</template>
          </el-input>
          <div class="div-button">
            <el-button type="primary" @click="toProvision" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>

        <el-dialog
            width="500px"
            title="计提盈余公积"
            v-model="dailVisiProvisionSurplus"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
          <el-input v-model="FdtaxRate"  placeholder="请录入法定盈余公积提取比例" style="margin-bottom: 5px">
            <template #append>%</template>
          </el-input>
          <el-input v-model="RytaxRate"  placeholder="请录入任意盈余公积提取比例">
            <template #append>%</template>
          </el-input>
          <div class="div-button">
            <el-button type="primary" @click="toProvisionSurplus" style="margin-right: 20px">确认计提</el-button>
          </div>
        </el-dialog>

        <el-drawer :size="1000"  v-model="drawerVisi" :with-header="false">
          <div style="width:100%; height: calc(100% - 10px); ">
            <iframe :src="pdffile" id="fra" style="width: 100%;height: 100%;"></iframe>
          </div>
        </el-drawer>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import apiURL from "@/components/apiUrl/api";

export default {
  name: "SubjectBalanceReport",
  data () {
    return {
      tableHeight: 0,
      search:{
        recordMonth:"",
        subjectType:"",
        subjectSort:"",
        subjectDetail:"",
        organOrDep:"",
        s_organid:"",
        s_depid:"",
      },
      OrganTreeData:[],
      organprops:{
        label:'text',value:'value',children:'children',
        checkStrictly: true,
        emitPath:false,
      },
      subjectBalanceData:[],
      exportTip: "",
      subjectTypeData: [],
      treeData:[],
      props:{
        label:'label',value:'id',children:'children',
        emitPath:false,
      },
      subjectDetailList:"",
      disabled:true,
      allSubjectDetail:[],
      pdffile:"",
      drawerVisi:false,
      taxes:"",
      CjtaxRate:"",
      JytaxRate:"",
      DfjytaxRate:"",
      dailVisiProvisionTaxes:false,
      dailVisiProvision:false,
      taxRate:"",
      FdtaxRate:"",
      RytaxRate:"",
      dailVisiProvisionSurplus:false,
    }
  },
  computed:{
    getNowTime() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month = now.getMonth(); //得到月份
      month = month + 1;
      month = month.toString().padStart(2, "0");
      return `${year}-${month}`;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTableHeight()
      window.removeEventListener("resize", this.calcTableHeight)
      window.addEventListener("resize", this.calcTableHeight)
      this.search.recordMonth = this.getNowTime+"";
      this.search.organOrDep = "company-"+sessionStorage.getItem("organ-organid")
      this.loadData()
      this.loadCode()
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calcTableHeight)
  },
  methods: {
    calcTableHeight() {
      let mainHeight = window.getComputedStyle(this.$refs.mainSector.$el).height
      if (mainHeight.indexOf("px") !== -1)
        mainHeight = Number(mainHeight.substring(0, mainHeight.indexOf("px")))
      else
        mainHeight = Number(mainHeight)
      this.tableHeight = mainHeight - 100
    },
    toRefresh() {
      this.loadData("refresh")
    },
    formatNumber(value) {
      if(value === 0 || value === ""){
        return ""
      }else{
        return new Intl.NumberFormat().format(value);
      }
    },
    loadCode() {
      this.$axios.post("/api/common/getcodelists", {codes: [ "SUBJECT_TYPE"]})
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.subjectTypeData = data.result.SUBJECT_TYPE
            } else {
              this.$alert(response.data.result, "获取代码列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取代码列表出错")
          })
      this.$axios.get("/api/common/getsubjecttree")
          .then((response) => {
            if (response.data.success) {
              this.treeData = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.post("/api/common/getallsubjectdetail", this.search)
          .then((response) => {
            if (response.data.success) {
              this.allSubjectDetail = response.data.result
            }
            else {
              this.$alert(response.data.result, "获取会计科目列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目列表出错")
          })
      this.$axios.get("/api/common/getqueryorgantree")
          .then((response) => {
            const data = response.data
            if (data.success) {
              this.OrganTreeData = data.result
            } else {
              this.$alert(response.data.result, "获取组织列表出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取组织列表出错")
          })
    },

    loadData(d) {
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }
      this.$axios.post("/api/entry/loadsubjectbalancedata", this.search)
          .then((response) => {
            if (response.data.success) {
              this.subjectBalanceData = response.data.result
              this.formatData(this.subjectBalanceData)
              if (d === "refresh") {
                this.$message({message: "刷新成功", type: "success"})
              }
            } else {
              this.$alert(response.data.result, "获取会计科目余额数据出错")
            }
          })
          .catch((error) => {
            this.$alert(error, "获取会计科目余额数据出错")
          })
    },

    formatData(info) {
      let data = JSON.parse(JSON.stringify(info));
      let temp = {};
      let pos = 0
      for (let i = 0; i < data.length; i++) {
        if (temp[data[i].SUBJECT_TYPE]) {
          temp[data[i].SUBJECT_TYPE]['total_init_debtor'] += parseFloat(data[i].total_init_debtor);
          temp[data[i].SUBJECT_TYPE]['total_init_credit'] += parseFloat(data[i].total_init_credit);
          temp[data[i].SUBJECT_TYPE]['total_debtor'] += parseFloat(data[i].total_debtor);
          temp[data[i].SUBJECT_TYPE]['total_credit'] += parseFloat(data[i].total_credit);
          temp[data[i].SUBJECT_TYPE]['total_end_debtor'] += parseFloat(data[i].total_end_debtor);
          temp[data[i].SUBJECT_TYPE]['total_end_credit'] += parseFloat(data[i].total_end_credit);
        } else {
          temp[data[i].SUBJECT_TYPE] = {};
          temp[data[i].SUBJECT_TYPE]['total_init_debtor'] = parseFloat(data[i].total_init_debtor);
          temp[data[i].SUBJECT_TYPE]['total_init_credit'] = parseFloat(data[i].total_init_credit);
          temp[data[i].SUBJECT_TYPE]['total_debtor'] = parseFloat(data[i].total_debtor);
          temp[data[i].SUBJECT_TYPE]['total_credit'] = parseFloat(data[i].total_credit);
          temp[data[i].SUBJECT_TYPE]['total_end_debtor'] = parseFloat(data[i].total_end_debtor);
          temp[data[i].SUBJECT_TYPE]['total_end_credit'] = parseFloat(data[i].total_end_credit);
        }
        if (i > 0 && data[i].SUBJECT_TYPE !== data[i - 1].SUBJECT_TYPE) {
          info.splice(i + pos, 0, {
            SUBJECT_TYPE: '合计',
            SUBJECT_TYPE_NAME: data[i - 1].SUBJECT_TYPE_NAME + '合计',
            SUBJECT_NAME: data[i - 1].SUBJECT_TYPE_NAME + '合计',
            SUBJECT_DETAIL: data[i - 1].SUBJECT_TYPE_NAME + '合计',
            total_init_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_init_debtor'].toFixed(2).replace(/\.00$/, ''),
            total_init_credit: temp[data[i - 1].SUBJECT_TYPE]['total_init_credit'].toFixed(2).replace(/\.00$/, ''),
            total_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_debtor'].toFixed(2).replace(/\.00$/, ''),
            total_credit: temp[data[i - 1].SUBJECT_TYPE]['total_credit'].toFixed(2).replace(/\.00$/, ''),
            total_end_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_end_debtor'].toFixed(2).replace(/\.00$/, ''),
            total_end_credit: temp[data[i - 1].SUBJECT_TYPE]['total_end_credit'].toFixed(2).replace(/\.00$/, ''),
          });
          if (i === data.length - 1) {
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_NAME: data[i].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_DETAIL: data[i].SUBJECT_TYPE_NAME + '合计',
              total_init_debtor: temp[data[i].SUBJECT_TYPE]['total_init_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_init_credit: temp[data[i].SUBJECT_TYPE]['total_init_credit'].toFixed(2).replace(/\.00$/, ''),
              total_debtor: temp[data[i].SUBJECT_TYPE]['total_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_credit: temp[data[i].SUBJECT_TYPE]['total_credit'].toFixed(2).replace(/\.00$/, ''),
              total_end_debtor: temp[data[i].SUBJECT_TYPE]['total_end_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_end_credit: temp[data[i].SUBJECT_TYPE]['total_end_credit'].toFixed(2).replace(/\.00$/, ''),
            });
          }
          pos++
        } else if (i === data.length - 1) {
          if (i === 0) {
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_NAME: data[i].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_DETAIL: data[i].SUBJECT_TYPE_NAME + '合计',
              total_init_debtor: temp[data[i].SUBJECT_TYPE]['total_init_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_init_credit: temp[data[i].SUBJECT_TYPE]['total_init_credit'].toFixed(2).replace(/\.00$/, ''),
              total_debtor: temp[data[i].SUBJECT_TYPE]['total_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_credit: temp[data[i].SUBJECT_TYPE]['total_credit'].toFixed(2).replace(/\.00$/, ''),
              total_end_debtor: temp[data[i].SUBJECT_TYPE]['total_end_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_end_credit: temp[data[i].SUBJECT_TYPE]['total_end_credit'].toFixed(2).replace(/\.00$/, ''),
            });
          } else {
            info.push({
              SUBJECT_TYPE: '合计',
              SUBJECT_TYPE_NAME: data[i - 1].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_NAME: data[i - 1].SUBJECT_TYPE_NAME + '合计',
              SUBJECT_DETAIL: data[i - 1].SUBJECT_TYPE_NAME + '合计',
              total_init_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_init_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_init_credit: temp[data[i - 1].SUBJECT_TYPE]['total_init_credit'].toFixed(2).replace(/\.00$/, ''),
              total_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_credit: temp[data[i - 1].SUBJECT_TYPE]['total_credit'].toFixed(2).replace(/\.00$/, ''),
              total_end_debtor: temp[data[i - 1].SUBJECT_TYPE]['total_end_debtor'].toFixed(2).replace(/\.00$/, ''),
              total_end_credit: temp[data[i - 1].SUBJECT_TYPE]['total_end_credit'].toFixed(2).replace(/\.00$/, ''),
            });
          }
          pos++
        }
      }

      let totalAmount1 = 0;
      let totalAmount2 = 0;
      let totalAmount3 = 0;
      let totalAmount4 = 0;
      let totalAmount5 = 0;
      let totalAmount6 = 0;

      for (let key in data) {
        totalAmount1 += parseFloat(data[key].total_init_debtor);
        totalAmount2 += parseFloat(data[key].total_init_credit);
        totalAmount3 += parseFloat(data[key].total_debtor);
        totalAmount4 += parseFloat(data[key].total_credit);
        totalAmount5 += parseFloat(data[key].total_end_debtor);
        totalAmount6 += parseFloat(data[key].total_end_credit);
      }
      info.push({
        SUBJECT_TYPE: '总计',
        SUBJECT_TYPE_NAME: '总计',
        SUBJECT_NAME: '总计',
        SUBJECT_DETAIL: '总计',
        total_init_debtor: totalAmount1.toFixed(2).replace(/\.00$/,''),
        total_init_credit: totalAmount2.toFixed(2).replace(/\.00$/,''),
        total_debtor: totalAmount3.toFixed(2).replace(/\.00$/,''),
        total_credit: totalAmount4.toFixed(2).replace(/\.00$/,''),
        total_end_debtor: totalAmount5.toFixed(2).replace(/\.00$/,''),
        total_end_credit: totalAmount6.toFixed(2).replace(/\.00$/,''),
      });
    },

    changeDetail(){
      let f=[]
      if(this.search.subjectSort !== "" && this.search.subjectSort !== null ) {
        this.disabled = false
        f = this.allSubjectDetail.filter((item) => {
          return Number(item.SUBJECT_SORT) === Number(this.search.subjectSort)
        })
      }else{
        this.disabled = true
      }
      this.subjectDetailList = f
      this.search.subjectDetail = ""
      this.loadData()
    },
    //导出
    doExport () {
      this.exportTip = "会计科目余额表导出Excel中..."
      this.$axios.post("/api/entry/exportsubjectbalancedata",{data: this.subjectBalanceData, recordMonth:this.search.recordMonth}, {
        responseType: "blob"
      })
          .then((response) => {
            setTimeout(this.exportTip = "", 3000)
            const err = response.headers["error-content"]
            if (typeof err === "undefined") {
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
              let fname = response.headers["content-disposition"].split("filename=")[1]
              fname = decodeURIComponent(fname)
              const a = this.$refs.expLink
              a.href = blobUrl
              a.setAttribute("download", fname)
              a.click()
            }
            else
              this.$alert(decodeURIComponent(err), "会计科目余额表导出出错")
          })
          .catch((error) => {
            this.exportTip = ""
            this.$alert(error, "会计科目余额表导出出错")
          })

    },

    toPrint (){
      this.drawerVisi = true
      this.pdffile = ""
      if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
        const parts = this.search.organOrDep.toString().split('-');
        if(parts[0] === "company"){
          this.search.s_organid = parts[1]
          this.search.s_depid = ""
        }else{
          this.search.s_organid = parts[0]
          this.search.s_depid = parts[1]
        }
      }else{
        this.search.s_organid = ""
        this.search.s_depid = ""
      }

      this.pdffile = apiURL+"/api/entry/printsubjectbalancedata?recordMonth="+this.search.recordMonth +"&s_organid="+this.search.s_organid +
          "&s_depid="+this.search.s_depid +"&subjectType="+ this.search.subjectType+
          "&subjectSort="+ this.search.subjectSort+"&subjectDetail="+ this.search.subjectDetail

    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log(row, column, rowIndex, columnIndex);
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        if (columnIndex === 0) {
          return [1, 3];
        } else if (columnIndex === 1 || columnIndex === 2) {
          return [0, 0];
        }
      } else {
        if (columnIndex === 0) {
          let spanArr = this.getSpanArr(this.subjectBalanceData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
        if (columnIndex === 1) {
          let spanArr = this.getSpanArr1(this.subjectBalanceData)
          const _row = spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      }
    },
    getSpanArr(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_TYPE=== arr[index - 1].SUBJECT_TYPE) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    getSpanArr1(arr) {
      if (arr) {
        const spanOneArr = []
        let concatOne = 0
        arr.forEach((item, index) => {
          if (index === 0) {
            spanOneArr.push(1)
          } else {
            if (item.SUBJECT_NAME=== arr[index - 1].SUBJECT_NAME) {
              spanOneArr[concatOne] += 1
              spanOneArr.push(0)
            } else {
              spanOneArr.push(1)
              concatOne = index
            }
          }
        })
        return spanOneArr
      }
    },
    cellStyleMethod({ row, columnIndex }) {
      if (row.SUBJECT_TYPE === '合计' || row.SUBJECT_TYPE === '总计') {
        return { 'text-align': 'right', 'font-weight': '600', 'background':'#f0f9eb' };
      }
      if(columnIndex === 0 || columnIndex === 1){
        return { 'background':'#f5f7fa'};
      }
    },

    showProvisionTaxes(){
      this.taxes = ""
      this.CjtaxRate = ""
      this.JytaxRate = ""
      this.DfjytaxRate = ""
      this.dailVisiProvisionTaxes = true
    },
    toProvisionTaxes() {
      if(this.taxes === ""){
        this.$message({message: "请录入应交增值税-销项税金额", type: "error"})
      }else if(this.CjtaxRate === ""){
        this.$message({message: "请录入城市维护建设税率", type: "error"})
      }else if(this.JytaxRate === ""){
        this.$message({message: "请录入教育费附加税率", type: "error"})
      }else if(this.DfjytaxRate === ""){
        this.$message({message: "请录入地方教育费附加税率", type: "error"})
      }else {
        if (this.search.organOrDep !== "" && this.search.organOrDep !== null) {
          const parts = this.search.organOrDep.toString().split('-');
          if (parts[0] === "company") {
            this.search.s_organid = parts[1]
            this.search.s_depid = ""
          } else {
            this.search.s_organid = parts[0]
            this.search.s_depid = parts[1]
          }
        } else {
          this.search.s_organid = ""
          this.search.s_depid = ""
        }
        this.search.taxes = this.taxes
        this.search.CjtaxRate = this.CjtaxRate
        this.search.JytaxRate = this.JytaxRate
        this.search.DfjytaxRate = this.DfjytaxRate
        this.$axios.post("/api/entry/toProvisionTaxes", this.search)
            .then((response) => {
              if (response.data.success) {
                this.dailVisiProvisionTaxes = false
                this.$message({message: "税金及附加计提完成", type: "success"})
                this.loadData()
              } else {
                this.$alert(response.data.result, "税金及附加计提出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "税金及附加计提出错")
            })
      }
    },
    doCheckout(){
      this.$confirm("确认会计结账吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
          const parts = this.search.organOrDep.toString().split('-');
          if(parts[0] === "company"){
            this.search.s_organid = parts[1]
            this.search.s_depid = ""
          }else{
            this.search.s_organid = parts[0]
            this.search.s_depid = parts[1]
          }
        }else{
          this.search.s_organid = ""
          this.search.s_depid = ""
        }
        this.$axios.post("/api/entry/doCheckout", this.search)
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "会计结账成功", type: "success"})
                this.loadData()
              } else {
                this.$alert(response.data.result, "会计结账出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "会计结账出错")
            })
      })
    },

    showProvision() {
      this.taxRate = ""
      this.dailVisiProvision = true
    },
    toProvision() {
      if(this.taxRate === ""){
        this.$message({message: "请录入适用企业所得税税率", type: "error"})
      }else {
        const d = [];
        this.subjectBalanceData.forEach(s => {
          if (s.SUBJECT_NAME === "本年利润" && s.SUBJECT_DETAIL === "当期损益") {
            d.push(s)
          }
        })
        if (d[0].total_end_credit > 0) {
          if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
            const parts = this.search.organOrDep.toString().split('-');
            if(parts[0] === "company"){
              this.search.s_organid = parts[1]
              this.search.s_depid = ""
            }else{
              this.search.s_organid = parts[0]
              this.search.s_depid = parts[1]
            }
          }else{
            this.search.s_organid = ""
            this.search.s_depid = ""
          }
          this.search.taxRate =  this.taxRate
          this.$axios.post("/api/entry/toProvision", this.search)
              .then((response) => {
                if (response.data.success) {
                  this.dailVisiProvision = false
                  this.$message({message: "企业所得税计提完成", type: "success"})
                  this.loadData()
                } else {
                  this.$alert(response.data.result, "企业所得税计提出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "企业所得税计提出错")
              })
        } else {
          this.$message({message: "本期亏损，不需计提企业所得税", type: "error"})
        }
      }
    },
    doTransfer(){
      this.$confirm("是否利润结转吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if(this.search.organOrDep !== "" && this.search.organOrDep !== null){
          const parts = this.search.organOrDep.toString().split('-');
          if(parts[0] === "company"){
            this.search.s_organid = parts[1]
            this.search.s_depid = ""
          }else{
            this.search.s_organid = parts[0]
            this.search.s_depid = parts[1]
          }
        }else{
          this.search.s_organid = ""
          this.search.s_depid = ""
        }
        this.$axios.post("/api/entry/doTransfer", this.search)
            .then((response) => {
              if (response.data.success) {
                this.dailVisiEntry = false
                this.$message({message: "本期利润结转完成", type: "success"})
                this.loadData()
              } else {
                this.$alert(response.data.result, "本期利润结转出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "本期利润结转出错")
            })
      })
    },
    showProvisionSurplus(){
      this.FdtaxRate = ""
      this.RytaxRate = ""
      this.dailVisiProvisionSurplus = true
    },
    toProvisionSurplus() {
      if(this.FdtaxRate === ""){
        this.$message({message: "请录入法定盈余公积提取比例", type: "error"})
      }else {
        const d = [];
        this.subjectBalanceData.forEach(s => {
          if (s.SUBJECT_NAME === "利润分配" && s.SUBJECT_DETAIL === "未分配利润") {
            d.push(s)
          }
        })
        if (d[0].total_end_credit > 0) {
          if (this.search.organOrDep !== "" && this.search.organOrDep !== null) {
            const parts = this.search.organOrDep.toString().split('-');
            if (parts[0] === "company") {
              this.search.s_organid = parts[1]
              this.search.s_depid = ""
            } else {
              this.search.s_organid = parts[0]
              this.search.s_depid = parts[1]
            }
          } else {
            this.search.s_organid = ""
            this.search.s_depid = ""
          }
          this.search.FdtaxRate = this.FdtaxRate
          this.search.RytaxRate = this.RytaxRate
          this.$axios.post("/api/entry/toProvisionSurplus", this.search)
              .then((response) => {
                if (response.data.success) {
                  this.dailVisiProvisionSurplus = false
                  this.$message({message: "盈余公积计提完成", type: "success"})
                  this.loadData()
                } else {
                  this.$alert(response.data.result, "盈余公积计提出错")
                }
              })
              .catch((error) => {
                this.$alert(error, "盈余公积计提出错")
              })
        } else {
          this.$message({message: "本期不需提取盈余公积！", type: "error"})
        }
      }
    },
    doMonthTransfer(){
      this.$confirm("确认月末结转吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$axios.post("/api/entry/domonthtransfer", {data: this.subjectBalanceData, recordMonth:this.search.recordMonth})
            .then((response) => {
              if (response.data.success) {
                this.$message({message: "月末结转成功", type: "success"})
              }
              else {
                this.$alert(response.data.result, "月末结转出错")
              }
            })
            .catch((error) => {
              this.$alert(error, "月末结转出错")
            })
      })
    },

    goDayRecord(row){
      this.$router.replace({name: "subject-Detail-Report", query:{sort:row.SUBJECT_SORT, detail:row.SUBJECT_DETAIL}})
    },
  }
}
</script>

<style scoped>

</style>
